import clsx from 'clsx';
import { Typography } from '@leaf/ui';

interface Props {
  priceChange: number;
}

const PriceChange: React.ComponentType<Props> = ({ priceChange }) => {
  return (
    <div className="flex items-center">
      {priceChange !== 0 && (
        <svg
          className={
            priceChange > 0 ? 'fill-status-green' : 'rotate-180 fill-status-red'
          }
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.60102 10H10.9336C10.9864 9.99879 11.0376 9.98235 11.0812 9.95265C11.1248 9.92296 11.1589 9.88128 11.1793 9.83265C11.1997 9.78402 11.2057 9.73051 11.1964 9.67859C11.1871 9.62666 11.163 9.57854 11.1269 9.54002L8.46062 6.80011C8.43524 6.77471 8.40511 6.75457 8.37194 6.74082C8.33877 6.72707 8.30322 6.72 8.26732 6.72C8.23141 6.72 8.19586 6.72707 8.16269 6.74082C8.12953 6.75457 8.09939 6.77471 8.07401 6.80011L5.40771 9.54002C5.37167 9.57854 5.34755 9.62666 5.33826 9.67859C5.32897 9.73051 5.3349 9.78402 5.35533 9.83265C5.37577 9.88128 5.40984 9.92296 5.45343 9.95265C5.49703 9.98235 5.54828 9.99879 5.60102 10Z" />
        </svg>
      )}
      <Typography
        className={clsx(
          priceChange > 0 && 'text-status-green',
          priceChange < 0 && 'text-status-red'
        )}
        variant="fine-print"
      >
        {priceChange.toLocaleString('en-AU', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
        %
      </Typography>
    </div>
  );
};

export default PriceChange;
