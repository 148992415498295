import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { Button, TextInput, Tooltip, Typography } from '@leaf/ui';
import {
  useCurrentInvestorUserQuery,
  useCurrentInvestorUserLazyQuery,
  useVerifyHoldingDetailsMutation,
} from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';
import { groupedCountries } from '@/utils/countries';

interface FormData {
  country: string;
  partialHin: string;
  postcode: string;
}

const hinTooltipContent = `
A HIN starts with the letter "X" followed by 10 numbers.
You can find your HIN on the top right hand corner of one of your CHESS holding statements.
An SRN starts with the letter "I" and can be found on letters sent to you by the company you own shares in.
`;

interface Props {
  onClose(): void;
  setStep: Dispatch<SetStateAction<'verify' | 'complete'>>;
}

const VerifyHoldingsLiteVerify: React.ComponentType<Props> = ({
  onClose,
  setStep,
}) => {
  useEffect(() => {
    analytics.track('verify_holdings_modal_viewed');
  }, []);

  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);

  const { data } = useCurrentInvestorUserQuery();
  const [currentInvestorUserQuery] = useCurrentInvestorUserLazyQuery();

  const [verifyHoldingDetails] = useVerifyHoldingDetailsMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['CurrentInvestorUser', 'MediaComments'],
  });

  const defaultPartialHin = useMemo(() => {
    if (data?.currentInvestorUser?.shareholding?.holderId) {
      return data.currentInvestorUser.shareholding.holderId.slice(
        data.currentInvestorUser.shareholding.holderId.length - 4
      );
    }

    if (data?.currentInvestorUser?.shareholderInformation?.partialHin) {
      return data.currentInvestorUser.shareholderInformation.partialHin;
    }

    return '';
  }, [data?.currentInvestorUser]);

  const defaultValues = useMemo(() => {
    return {
      country:
        data?.currentInvestorUser?.shareholderInformation?.country ??
        'Australia',
      partialHin: defaultPartialHin,
      postcode:
        data?.currentInvestorUser?.shareholderInformation?.postcode ?? '',
    };
  }, [data?.currentInvestorUser, defaultPartialHin]);

  const { formatAndShowError, showAlert } = useAlert();

  const { control, formState, handleSubmit, register, reset, setError, watch } =
    useForm<FormData>({
      defaultValues: {
        partialHin: defaultPartialHin,
      },
      resolver: yupResolver(
        yup.object().shape({
          country: yup.string().trim().required('Required.'),
          partialHin: yup.string().trim().required('Required.'),
          postcode: yup.string().when('country', {
            is: 'Australia',
            otherwise: () => yup.string(),
            then: () =>
              yup
                .string()
                .trim()
                .matches(/^[0-9]{4}$/, 'Post code should be a 4-digit number.'),
          }),
        })
      ),
    });
  useEffect(() => {
    reset({ ...defaultValues });
    setIsShowErrorMessage(false);
  }, [defaultValues, reset]);

  const partialHin = watch('partialHin');
  const postcode = watch('postcode');
  const country = watch('country');

  const onSubmit = handleSubmit(async ({ country, partialHin, postcode }) => {
    setIsShowErrorMessage(false);
    const verify = () =>
      verifyHoldingDetails({
        variables: {
          country: country,
          partialHin: partialHin.slice(partialHin.length - 4),
          postcode: country === 'Australia' ? postcode : undefined,
        },
      });

    await verify()
      .then(async () => {
        await currentInvestorUserQuery().then(async ({ data }) => {
          if (data?.currentInvestorUser?.isHoldingVerified) {
            setIsShowErrorMessage(false);
            analytics.track('verify_holdings_modal_verified');
            return setStep('complete');
          }
          analytics.track('verify_holdings_modal_not_verified');
          setIsShowErrorMessage(true);
          showAlert({
            description: 'We were unable to verify your holdings.',
            variant: 'error',
          });
        });
      })
      .catch(formatAndShowError);
  });

  useEffect(() => {
    if (isShowErrorMessage) {
      setError('partialHin', {
        message: 'Enter the last 4 digits of your HIN/SRN',
        type: 'custom',
      });
      setError('postcode', {
        message: 'No matching postcode',
        type: 'custom',
      });
    }
  }, [isShowErrorMessage, setError]);

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-3">
        <div>
          <div className="mb-1 flex items-center justify-between space-x-2">
            <div className="flex items-center gap-2">
              <label htmlFor="partialHin">
                <Typography component="span" variant="button">
                  HIN/SRN
                </Typography>
              </label>
              <Tooltip
                hover
                buttonClassName="flex items-center"
                content={hinTooltipContent}
              />
            </div>
          </div>
          <Controller
            control={control}
            name="partialHin"
            render={({ field }) => (
              <InputMask
                mask="\*\*\*\*\*\*\*9999"
                value={field.value}
                onChange={field.onChange}
              >
                <TextInput
                  className="mb-2"
                  error={!!formState.errors.partialHin?.message}
                  helperText={formState.errors.partialHin?.message}
                  id="hin"
                  placeholder="X******1234"
                  tooltipContent={hinTooltipContent}
                />
              </InputMask>
            )}
          />
          {!formState.errors.partialHin?.message && (
            <Typography className="text-text-grey" component="span">
              Please enter the last 4 digits of your HIN/SRN
            </Typography>
          )}
        </div>
        <div>
          <label className="mb-1 block" htmlFor="country">
            <Typography component="span" variant="button">
              Country
            </Typography>
          </label>
          <select {...register('country')} className="input" id="country">
            {groupedCountries.map((country) => (
              <option key={country.abbr} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className={country === 'Australia' ? 'block' : 'hidden'}>
          <TextInput
            {...register('postcode')}
            autoComplete="postal-code"
            error={!!formState.errors.postcode?.message}
            helperText={formState.errors.postcode?.message}
            id="postcode"
            label="Postcode"
            placeholder="3000"
          />
        </div>
        {isShowErrorMessage && (
          <Typography className="text-status-red" variant="body-regular">
            Your holding information could not be verified. Re-enter your
            information and try again.
          </Typography>
        )}
      </div>
      <div className="mt-8 flex-row-reverse gap-4 md:flex">
        <Button
          fullWidth
          disabled={
            formState.isSubmitting ||
            !partialHin ||
            !country ||
            (country === 'Australia' && !postcode)
          }
          type="submit"
          onClick={() =>
            analytics.track('verify_holdings_modal_form_submitted')
          }
        >
          Verify
        </Button>
        <Button
          fullWidth
          disabled={formState.isSubmitting}
          variant="tertiary"
          onClick={() => {
            onClose();
            setIsShowErrorMessage(false);
          }}
        >
          Back
        </Button>
      </div>
    </form>
  );
};

export default VerifyHoldingsLiteVerify;
