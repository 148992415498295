import { useEffect } from 'react';
import analytics from '@analytics';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { useTimeout } from 'usehooks-ts';
import { Button, Typography } from '@leaf/ui';

interface Props {
  onClose(): void;
}

const VerifyHoldingsLiteComplete: React.ComponentType<Props> = ({
  onClose,
}) => {
  useEffect(() => {
    import('@lottiefiles/lottie-player');
    analytics.track('verify_holdings_modal_complete');
  }, []);

  useTimeout(onClose, 3500);
  return (
    <div className="flex flex-col items-start gap-8">
      <div className="flex justify-center">
        <CheckCircleIcon className="h-20 w-20 text-company-primary" />
      </div>
      <div className="space-y-2">
        <Typography className="text-text-main" variant="heading-4">
          {'Holdings verified!'}
        </Typography>
        <Typography className="text-text-grey" variant="body-regular">
          Your investor profile will now display a &apos;verified&apos; badge
        </Typography>
      </div>
      <Button fullWidth onClick={onClose}>
        Continue browsing
      </Button>
    </div>
  );
};

export default VerifyHoldingsLiteComplete;
