// CookieBanner.tsx
import React, { useEffect } from 'react';
import analytics from '@analytics';
import { CheckIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import {
  getCookieConsent,
  setCookieConsent,
  CookieConsent,
} from '@leaf/helpers';
import { maybeAddRelNofollow } from '@leaf/helpers';
import { Button, Typography } from '@leaf/ui';
import CustomiseCookies from '@/components/cookie-manager/customise-cookies';
import { useCookieBanner } from '@/contexts/cookie-banner-context';
import { useCurrentCompany } from '@/contexts/current-company-context';

/*
  A banner that appears at the bottom of the screen to inform the user that we use cookies.

  Note that 'cookies' is an outdated term, it's more about user data being used for analytics or marketing.

  There are multiple types of cookies:
    - Necessary (permanently on)
    - Analytics (toggles Segment / LogRocket and our own analytics (see TrackingEventMutation, which stores data in the tracking_investor_hub table))
    - Marketing (we currently don't use marketing cookies but here for future proofing)

  The banner has three buttons:
    - Customise
    - Reject
    - Accept all

  The banner is hidden if the user has already accepted or rejected cookies. This is stored in a cookie called 'cookieConsent' as JSON:

      {
        analytics: true,
        marketing: false
      }

  To set these individually a user can click 'Customise', which opens a form with checkboxes.
*/
const CookieBanner: React.FC = () => {
  const {
    currentCompany: {
      ticker: { marketListingKey },
    },
  } = useCurrentCompany();

  const { cookieBannerVisible, setCookieBannerVisible } = useCookieBanner();
  const [showCustomiseCookies, setShowCustomiseCookies] = React.useState(false);
  const { control, handleSubmit } = useForm<CookieConsent>({
    defaultValues: { analytics: false },
  });

  useEffect(() => {
    const cookieConsent = getCookieConsent();

    if (cookieConsent === undefined) {
      setCookieBannerVisible(true);
    }
  }, [setCookieBannerVisible]);

  const handleCookieConsent = (consents: CookieConsent) => {
    setCookieConsent(consents);
    setCookieBannerVisible(false);
    analytics.loadIfConsented();
  };

  const onSubmit = (data: CookieConsent) => {
    handleCookieConsent(data);
  };

  if (!cookieBannerVisible) return null;

  return (
    <>
      <div className="fixed bottom-0 z-[999] w-full border-company-accent-text bg-company-accent text-company-accent-text">
        <div className="relative mx-auto flex h-full max-h-[90vh] max-w-screen-xl flex-col items-center justify-center gap-5 p-6 sm:flex-row sm:justify-start sm:space-y-0 md:gap-10">
          {showCustomiseCookies ? (
            <CustomiseCookies
              control={control}
              onCancel={() => setShowCustomiseCookies(false)}
              onSubmit={handleSubmit(onSubmit)}
            />
          ) : (
            <>
              <div>
                <Typography
                  className="mb-2 font-heading !font-normal"
                  variant="heading-4"
                >
                  We value your privacy
                </Typography>
                <Typography className="font-body">
                  We use cookies, including those from select third parties, to
                  enhance your experience on our website and to help improve our
                  services as specified in our{' '}
                  <a
                    className="underline"
                    href="https://www.investorhub.com/privacy-centre"
                    rel={`noopener noreferrer ${maybeAddRelNofollow(
                      marketListingKey
                    )}`}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  . By clicking &quot;Accept all&quot;, you consent to our use
                  of cookies.
                </Typography>
              </div>

              <div className="flex flex-1 items-center justify-center gap-4 md:justify-end">
                <div
                  className="cursor-pointer whitespace-nowrap rounded-lg bg-white/20 px-3 py-2 font-body transition-colors hover:bg-white/20"
                  onClick={() => setShowCustomiseCookies(true)}
                >
                  Customise
                </div>
                <div
                  className="cursor-pointer whitespace-nowrap rounded-lg bg-white/20 px-3 py-2 font-body transition-colors hover:bg-white/20"
                  onClick={() =>
                    handleCookieConsent({ analytics: false, marketing: false })
                  }
                >
                  Reject
                </div>
                <Button
                  className="bg-company-primary text-company-primary-text"
                  endIcon={<CheckIcon className="size-5" />}
                  onClick={() =>
                    handleCookieConsent({ analytics: true, marketing: true })
                  }
                >
                  Accept all
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CookieBanner;
