import React, { useMemo } from 'react';
import { ArrowUturnLeftIcon } from '@heroicons/react-v2/20/solid';
import {
  HandThumbUpIcon,
  UserPlusIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  VideoCameraIcon,
  UsersIcon,
} from '@heroicons/react-v2/24/outline';
import { BoltIcon } from '@heroicons/react-v2/24/solid';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { Typography, Button } from '@leaf/ui';
import {
  HubNotificationType,
  HubNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useUpsertFollowMutation,
} from '@/apollo/generated';
import { ShareholderBadgeIcon } from '@/components/utils/shareholder-badge';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompany } from '@/contexts/current-company-context';
import routes from '@/utils/routes';

interface Props {
  datetime?: string;
  hubFeature?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['hubFeature'];
  hubFollow?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['hubFollow'];
  isRead?: boolean;
  media?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['media'];
  mediaComment?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['mediaComment'];
  mediaCommentLike?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['mediaCommentLike'];
  mediaCommentReply?: NonNullable<
    NonNullable<HubNotificationsQuery['hubNotifications']>[0]
  >['mediaCommentReply'];
  notificationId: string;
  // variant:
  // REPLY - Company left a new reply on announcement/update
  // LIKE - Someone else liked your question
  // PROFILE_FOLLOW - Someone else started following your profile
  // QUESTION_FOLLOW - Someone else started following your question
  // VIDEO - Company uploaded a new video
  // FOLLOWED_QUESTION - New question on announcement/update that you are following / Someone you are following asked a question
  // FOLLOWED_REPLY - New company's reply on announcement/update/question that you are following
  // FEATURE - New IH feature release
  variant: HubNotificationType;
}

const NotificationStatsCard: React.ComponentType<Props> = ({
  datetime,
  hubFeature,
  hubFollow,
  isRead = false,
  media,
  mediaComment,
  mediaCommentLike,
  mediaCommentReply,
  notificationId,
  variant,
}) => {
  const { push } = useRouter();

  const {
    currentCompany: { name: companyName },
  } = useCurrentCompany();

  const replyUsername = useMemo(() => {
    if (!mediaCommentReply || !mediaCommentReply.companyAuthor) return '';

    const {
      companyAuthor: { firstName, lastName },
    } = mediaCommentReply;
    if (mediaCommentReply.useCompanyAsUsername) return companyName;
    if (firstName || lastName) return `${firstName} ${lastName}`.trim();
    return companyName;
  }, [companyName, mediaCommentReply]);

  const { formatAndShowError } = useAlert();

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['HubNotifications'],
  });

  const [upsertFollow] = useUpsertFollowMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'MediaFollow',
      'MediaComments',
      'UserProfileFollow',
      'HubNotifications',
    ],
  });

  const renderIconBgColor = () => {
    switch (variant) {
      case HubNotificationType.Like:
        return 'bg-cyan-50';
      case HubNotificationType.ProfileFollow:
        return 'bg-violet-50';
      case HubNotificationType.Video:
        return 'bg-amber-50';
      case HubNotificationType.Feature:
        return 'bg-fuchsia-50';

      default:
        return 'bg-green-50';
    }
  };

  const renderIcon = () => {
    switch (variant) {
      case HubNotificationType.Reply:
        return <ArrowUturnLeftIcon className="h-5 w-5 text-green-600" />;
      case HubNotificationType.Like:
        return <HandThumbUpIcon className="h-5 w-5 text-cyan-600" />;
      case HubNotificationType.ProfileFollow:
        return <UserPlusIcon className="h-5 w-5 text-violet-600" />;
      case HubNotificationType.QuestionFollow:
        return (
          <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 text-green-600" />
        );
      case HubNotificationType.Video:
        return <VideoCameraIcon className="h-5 w-5 text-amber-600" />;
      case HubNotificationType.FollowedQuestion:
        return (
          <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 text-green-600" />
        );
      case HubNotificationType.FollowedReply:
        return <ArrowUturnLeftIcon className="h-5 w-5 text-green-600" />;

      default:
        return <UsersIcon className="h-5 w-5 text-fuchsia-600" />;
    }
  };

  const renderInvestorUsername = () => {
    if (hubFollow) {
      return hubFollow.investorUser?.username;
    }

    if (mediaCommentLike) {
      return mediaCommentLike.investorUser?.username;
    }

    return '-';
  };

  const getIsVerifiedShareholder = () => {
    if (hubFollow) {
      return hubFollow.investorUser?.isHoldingVerified;
    }

    return false;
  };

  const renderInvestorNameAndBage = () => {
    return (
      <>
        <span className="typography-button text-text-main">
          {renderInvestorUsername()}{' '}
        </span>
        {getIsVerifiedShareholder() && (
          <ShareholderBadgeIcon className="inline-block h-5 w-5" />
        )}
      </>
    );
  };

  const renderMediaTitle = () => {
    switch (variant) {
      case HubNotificationType.QuestionFollow:
        return (
          hubFollow?.followingMediaComment?.media?.mediaAnnouncement?.header ||
          hubFollow?.followingMediaComment?.media?.mediaUpdate?.title
        );

      case HubNotificationType.FollowedQuestion:
        return (
          hubFollow?.followingMedia?.mediaAnnouncement?.header ||
          hubFollow?.followingMedia?.mediaUpdate?.title
        );

      case HubNotificationType.Like:
        return (
          mediaCommentLike?.comment?.media?.mediaAnnouncement?.header ||
          mediaCommentLike?.comment?.media?.mediaUpdate?.title
        );

      case HubNotificationType.Video:
        return media?.mediaAnnouncement?.header || media?.mediaUpdate?.title;

      case HubNotificationType.Reply:
        return (
          mediaComment?.media?.mediaAnnouncement?.header ||
          mediaComment?.media?.mediaUpdate?.title
        );

      case HubNotificationType.FollowedReply:
        return (
          hubFollow?.followingMediaComment?.media?.mediaAnnouncement?.header ||
          hubFollow?.followingMediaComment?.media?.mediaUpdate?.title
        );

      default:
        return '-';
    }
  };

  const renderNewFeatureDescription = () => {
    if (hubFeature) {
      return hubFeature.description;
    }

    return '-';
  };

  const renderTitleText = () => {
    switch (variant) {
      case HubNotificationType.Reply:
        return (
          <Typography className="text-text-main">
            {`${replyUsername} replied to your question about `}
            <span className="typography-button">
              {renderMediaTitle() || '-'}
            </span>
          </Typography>
        );
      case HubNotificationType.Like:
        return (
          <Typography className="text-text-main">
            {renderInvestorNameAndBage()}
            <span>
              liked your question about{' '}
              <span className="typography-button">
                {renderMediaTitle() || '-'}
              </span>
            </span>
          </Typography>
        );
      case HubNotificationType.ProfileFollow:
        return (
          <Typography className="text-text-main">
            {renderInvestorNameAndBage()}
            <span> started following you</span>
          </Typography>
        );
      case HubNotificationType.QuestionFollow:
        return (
          <Typography className="text-text-main">
            {renderInvestorNameAndBage()}
            <span>
              {' '}
              started following a question you asked about{' '}
              <span className="typography-button">
                {renderMediaTitle() || '-'}
              </span>
            </span>
          </Typography>
        );
      case HubNotificationType.Video:
        return (
          <Typography className="text-text-main">
            {`${companyName} uploaded a video to `}
            <span className="typography-button">
              {renderMediaTitle() || '-'}
            </span>
          </Typography>
        );
      case HubNotificationType.FollowedQuestion:
        return (
          <Typography className="text-text-main">
            New question asked on{' '}
            <span className="typography-button">
              {renderMediaTitle() || '-'}
            </span>
          </Typography>
        );
      case HubNotificationType.FollowedReply:
        return (
          <Typography className="text-text-main">
            {`${replyUsername} replied to a question about `}
            <span className="typography-button">
              {renderMediaTitle() || '-'}
            </span>
          </Typography>
        );

      default:
        return (
          <Typography className="text-text-main">
            {renderNewFeatureDescription()}
          </Typography>
        );
    }
  };

  const buttonText = () => {
    switch (variant) {
      case HubNotificationType.ProfileFollow:
        return hubFollow?.isProfileFollowBack ? 'Following' : 'Follow back';
      case HubNotificationType.Video:
        return 'Watch';
      case HubNotificationType.Feature:
        return 'Check it out';

      default:
        return 'View';
    }
  };

  const getButtonAction = () => {
    switch (variant) {
      case HubNotificationType.Feature:
        return push(hubFeature?.buttonLink ?? '');

      case HubNotificationType.ProfileFollow:
        return onClickFollowButton();

      case HubNotificationType.FollowedQuestion:
        return push(
          hubFollow?.followingMedia?.mediaAnnouncement?.id
            ? routes.interactiveAnnouncement.href(
                hubFollow.followingMedia?.mediaAnnouncement?.id || ''
              )
            : routes.activityUpdate.href(
                hubFollow?.followingMedia?.mediaUpdate?.slug || ''
              )
        );

      case HubNotificationType.Like:
        return push(
          mediaCommentLike?.comment?.media?.mediaAnnouncement?.id
            ? routes.interactiveAnnouncement.href(
                mediaCommentLike?.comment?.media?.mediaAnnouncement.id
              )
            : routes.activityUpdate.href(
                mediaCommentLike?.comment?.media?.mediaUpdate?.slug || ''
              )
        );

      case HubNotificationType.Video:
        return push(
          media?.mediaAnnouncement?.id
            ? routes.interactiveAnnouncement.href(media.mediaAnnouncement.id)
            : routes.activityUpdate.href(media?.mediaUpdate?.slug || '')
        );

      case HubNotificationType.Reply:
        return push(
          mediaComment?.media?.mediaAnnouncement?.id
            ? routes.interactiveAnnouncement.href(
                mediaComment.media.mediaAnnouncement.id
              )
            : routes.activityUpdate.href(
                mediaComment?.media?.mediaUpdate?.slug || ''
              )
        );

      default:
        return '';
    }
  };

  const onClickFollowButton = async () => {
    if (hubFollow) {
      await upsertFollow({
        variables: {
          follow: {
            followingInvestorUserId: hubFollow.investorUser?.id,
            invalidated: hubFollow.isProfileFollowBack,
          },
        },
      });
    }
  };

  const onClickStatsCard = async () => {
    markNotificationAsRead({
      variables: {
        notificationId,
      },
    })
      .then(() => {
        getButtonAction();
      })
      .catch(formatAndShowError);
  };

  return (
    <div
      key={`notification-${notificationId}`}
      className="space-y-4 p-6 hover:bg-secondary-grey-light-2"
      role="button"
      onClick={onClickStatsCard}
    >
      {variant === HubNotificationType.Feature && (
        <div className="inline-flex items-center gap-1 rounded-2xl bg-company-accent px-2 py-0.5">
          <div className="h-2.5">
            <BoltIcon className="h-2.5 w-2.5 text-company-accent-text" />
          </div>
          <Typography
            className="text-company-accent-text"
            variant="fine-print-bold"
          >
            NEW FEATURE
          </Typography>
        </div>
      )}
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            {!isRead && (
              <div className="h-2 w-2 rounded-full bg-company-primary" />
            )}
            <div
              className={`h-10 w-10 ${renderIconBgColor()} rounded-full p-2.5`}
            >
              {renderIcon()}
            </div>
          </div>
          <div>
            {renderTitleText()}
            {datetime && (
              <Typography className="text-text-grey" variant="fine-print">
                {dayjs(datetime).format('HH:MM, DD/MM/YYYY')}
              </Typography>
            )}
          </div>
        </div>
        <Button className="shrink-0" variant="secondary">
          {buttonText()}
        </Button>
      </div>
    </div>
  );
};

export default NotificationStatsCard;
