import React from 'react';
import { Popover } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react-v2/24/solid';
import { Button, Typography } from '@leaf/ui';

interface Props {
  children?: React.ReactNode;
  totalNotifications?: number;
}

const NotificationLayout: React.ComponentType<Props> = ({
  children,
  totalNotifications,
}) => {
  return (
    <Popover.Panel>
      {({ close }) => (
        <div className="absolute right-0 top-0 z-50 h-screen w-full rounded-lg border border-secondary-grey-light bg-white shadow-lg sm:h-auto sm:max-h-[90vh] sm:w-[540px] sm:max-w-xl sm:overflow-hidden lg:top-8">
          <div className="flex items-center justify-between border-b border-secondary-grey-light px-6 py-4">
            <div className="flex items-center gap-2">
              <Typography className="text-text-main" variant="heading-4">
                Notifications
              </Typography>
              {totalNotifications ? (
                <Typography
                  className="rounded-2xl bg-company-primary px-2 py-0.5 text-company-primary-text"
                  component="span"
                  variant="badge"
                >
                  {totalNotifications}
                </Typography>
              ) : null}
            </div>
            <Button
              startIcon={<XMarkIcon className="h-6 w-6 text-text-main" />}
              variant="tertiary"
              onClick={() => close()}
            ></Button>
          </div>
          <div className="max-h-[90vh] overflow-scroll pb-20">{children}</div>
        </div>
      )}
    </Popover.Panel>
  );
};

export default NotificationLayout;
