import { createContext, useContext } from 'react';
import { CurrentCompanyQuery } from '@/apollo/generated';
import { WebsiteProvider } from '@/contexts/website-context';
import { getTranslator } from '@/i18n';
import { currentCompanyRegistries, Registry } from '@/utils/company/helper';

interface CurrentCompanyContext {
  currentCompany: NonNullable<CurrentCompanyQuery['currentCompany']>;
  currentCompanyMarketKey: string;
  currentCompanyRegistry?: Registry | null;
  currentCompanyTicker: string;
  hasCompanyShareholderOfferPermission: boolean;
  hasLiveGovernancePage: boolean;
  isPremium: boolean;
  isUK: boolean;
  translate: (
    key: string,
    params?:
      | {
          [key: string]: string | number;
        }
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
}

interface Props {
  children?: React.ReactNode;
  currentCompany: NonNullable<CurrentCompanyQuery['currentCompany']>;
  host: string;
  isPreviewMode: boolean;
}

export const CurrentCompanyContext =
  createContext<CurrentCompanyContext | null>(null);

const CurrentCompanyProvider: React.ComponentType<Props> = ({
  children,
  currentCompany,
}) => {
  let locale = null;

  const isUK = ['aqse', 'lse'].includes(
    currentCompany?.ticker?.marketKey ?? ''
  );

  if (isUK) locale = 'en-UK';
  if (currentCompany?.ticker?.marketKey === 'asx') locale = 'en-AU';

  const translate = getTranslator(locale);

  return (
    <CurrentCompanyContext.Provider
      value={{
        currentCompany: currentCompany ?? currentCompany,
        currentCompanyMarketKey:
          currentCompany?.ticker.marketKey.toUpperCase() ??
          currentCompany.ticker.marketKey.toUpperCase(),
        currentCompanyRegistry: currentCompanyRegistries.find(
          (registry) => registry.key === currentCompany?.investorHub?.registry
        ),
        currentCompanyTicker:
          currentCompany?.ticker.listingKey.toUpperCase() ??
          currentCompany.ticker.listingKey.toUpperCase(),
        hasCompanyShareholderOfferPermission:
          currentCompany?.hasCompanyShareholderOfferPermission ?? false,
        hasLiveGovernancePage: currentCompany?.hasLiveGovernancePage ?? false,
        isPremium: currentCompany?.isPremium ?? true,
        isUK,
        translate,
      }}
    >
      {children}
    </CurrentCompanyContext.Provider>
  );
};

export function useCurrentCompany() {
  const context = useContext(CurrentCompanyContext);

  if (!context) {
    throw new Error(
      'useCurrentCompany must be used within a CurrentCompanyProvider'
    );
  }

  return context;
}

/**
 * This HOC can only be used
 * if the page is wrapped inside `requireCurrentCompany` middleware.
 */
export function withCurrentCompanyContext<P>(
  Component: React.ComponentType<P>
) {
  const WithCurrentCompanyContext: React.ComponentType<P & Props> = (
    pageProps
  ) => {
    const { publishedWebsite, unpublishedWebsite } = pageProps.currentCompany;
    return (
      <CurrentCompanyProvider {...pageProps}>
        <WebsiteProvider
          isPreviewMode={pageProps.isPreviewMode}
          publishedWebsite={publishedWebsite}
          unpublishedWebsite={unpublishedWebsite}
        >
          <Component {...pageProps} />
        </WebsiteProvider>
      </CurrentCompanyProvider>
    );
  };

  return WithCurrentCompanyContext;
}
